import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useNavigate } from 'react-router-dom';

import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';
import useGlobalState from '../hooks/useGlobalState';
import CircularLoader from 'components/ui/CircularLoader';
import { getSettings, getTemplates, getTools, getMicrobizzDevice, updateMicrobizzDevice } from 'data/api';

const DeviceChooseInstallation = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [owner, setOwner] = useState(null);
	const [tools, setTools] = useState([]);
	const [tool, setTool] = useState('');
	const [template, setTemplate] = useState('');
	const [templates, setTemplates] = useState([]);

	useEffect(() => {
		dispatch({ headerTitle: 'Vælg anlæg', showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			const settings = await getSettings();

			const ownerSetting = settings.find(d => d.key === 'microbizzowner');

			if (ownerSetting !== undefined) {
				setOwner(ownerSetting);

				const mbDevice = await getMicrobizzDevice(ownerSetting.value, state.onboardedDevice.uuid);

				if (mbDevice?.status === 'ok') {
					setTool(mbDevice.result.toolId);
					setTemplate(mbDevice.result.templateId);
				}

				const toolsData = await getTools(ownerSetting.value, state.onboardedDevice.registry.org.uuid);

				if (toolsData?.status === 'ok') {
					setTools(toolsData.result);
				}

				const templatesData = await getTemplates(ownerSetting.value);

				if (templatesData?.status === 'ok') {
					setTemplates(templatesData.result.filter(d => d.active === 1));
				}
			}

			setLoading(false);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const handleSave = async () => {
		setLoading(true);

		const deviceUpdate = await updateMicrobizzDevice(owner.value, state.onboardedDevice.uuid, { toolId: tool, templateId: template });

		if (deviceUpdate.status === 'ok') {
			navigate('/device');
		}
	}

	const handleToolChange = (value) => {
		setTool(value);

		dispatch({ selectedTool: value });
	}

	const handleTemplateChange = (value) => {
		setTemplate(value);

		dispatch({ selectedTemplate: value });
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={state.onboardedDevice.uuname} icon={<TimelineIcon />} cancelRoute="/device">
			{!loading ? (
				<>
					{tools?.length && templates?.length ? (
						<>
							<Typography variant="h3" align="center">Vælg anlæg</Typography>

							<FormControl fullWidth style={{ marginTop: 20 }}>
								<InputLabel id="select-tool-label">Anlæg</InputLabel>
								<Select
									labelId="select-tool-label"
									id="select-tool"
									value={tool}
									label="Anlæg"
									onChange={(event) => handleToolChange(event.target.value)}
								>
									{tools.map(d => {
										return <MenuItem key={d.toolId} value={d.toolId}>{d.name}</MenuItem>;

									})}
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ marginTop: 20 }}>
								<InputLabel id="select-template-label">Skabelon</InputLabel>
								<Select
									labelId="select-template-label"
									id="select-template"
									value={template}
									label="Skabelon"
									onChange={(event) => handleTemplateChange(event.target.value)}
								>
									{templates.map(d => {
										return <MenuItem key={d.templateId} value={d.templateId}>{d.name}</MenuItem>;

									})}
								</Select>
							</FormControl>

							<Box className={classes.buttonPanel} justifyContent="center">
								<Button className={classes.greenButton} onClick={() => handleSave()}>Gem</Button>
							</Box>

						</>) : (
						<Typography>Der findes ingen anlæg/skabeloner på valgte kunde</Typography>
					)}
				</>
			) : (<CircularLoader />)}
		</OnboardContainer>
	)
}

export default DeviceChooseInstallation;