import React, { useEffect, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';

import mainStyles from 'styles/mainStyles';
import CircularLoader from 'components/ui/CircularLoader';
import OnboardContainer from 'components/OnboardContainer';
import { onboard } from 'data/api';
import useGlobalState from '../hooks/useGlobalState';
import { defaultGlobalState } from 'providers/GlobalStateProvider';

const OnboardFinish = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [error, setError] = useState(null);

	useEffect(() => {
		dispatch({ headerTitle: '', showMobileStepper: false, showBottomNavigation: false });
	}, [dispatch]);

	useEffect(() => {
		async function sendData() {
			let postData = {
				user: state.user,
				owner: state.owner,
				uuname: state.uuname,
				deviceType: state.selectedDeviceType,
				registry: state.selectedRegistry,
				building: state.selectedBuilding,
				customer: state.selectedCustomer,
				name: state.selectedName,
				description: state.selectedDescription,
				images: state.selectedImages,
				template: state.selectedTemplate,
				tool: state.selectedTool,
				package: state.selectedPackage,
				datafields: state.selectedDatafields,
			}
			// console.log(postData);

			const result = await onboard(postData);
			console.log(result.data);

			if (result.status !== 200) {
				setError(true);
			} else {
				delete defaultGlobalState.user;
				dispatch(defaultGlobalState);
				localStorage.removeItem('globalState');

				navigate('/device/' + result.data);
			}
		}

		sendData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleScan = () => {
		navigate('/scan');
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan" noCancel={true}>
			{!error ?
				<>
					<Typography align='center' variant='h3' style={{ marginTop: 20, marginBottom: 50 }}>Onboarder enheden</Typography>
					<CircularLoader style={{ marginBottom: 30 }} />
				</>
				:
				<>
					<Typography color="error">Der opstod en fejl ved onboading, prøv venligst igen!</Typography>

					<Box className={classes.buttonPanel} justifyContent="center">
						<Button className={classes.greenButton} onClick={() => handleScan()}>Scan</Button>
					</Box>

				</>
			}
		</OnboardContainer>
	)
}

export default OnboardFinish;