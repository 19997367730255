import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import { getDevices } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';
import OnboardContainer from 'components/OnboardContainer';

const DevicesList = () => {
	const classes = mainStyles();
	const [, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [devices, setDevices] = useState([]);
	const [devicesFiltered, setDevicesFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);

	useEffect(() => {
		dispatch({ headerTitle: 'Enheder', showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch]);

	useEffect(() => {
		console.log('useEffect');

		async function fetchData() {
			const devicesData = await getDevices();

			if (devicesData) {
				setDevices(devicesData);
				setDevicesFiltered(devicesData);
			}

			setLoading(false);
		}

		fetchData();
	}, []);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devicesFiltered.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		const rpp = parseInt(event.target.value, 10);
		setRowsPerPage(rpp);
		setPage(0);
	}

	const handleRowClick = (event, uuid) => {
		navigate('/device/' + uuid);
	}

	const handleSearch = (event) => {
		setPage(0);

		let newDevices = devices.filter(o =>
			o.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
			o.uuname.toLowerCase().includes(event.target.value.toLowerCase())
		);
		setDevicesFiltered(newDevices);
	}

	const handleAddDevice = () => {
		navigate('/device/add');
	}

	const onboardDevice = (device) => {
		navigate('/' + device.uuname);
	}

	return (
		<OnboardContainer hasPadding={false} title="" subtitle="" icon={null} cancelRoute="/scan" noCancel={true} noAvatar={true}>
			{!loading ? (
				<>
					<TextField
						id="table-search"
						label="Søg på navn eller nummer"
						type="search"
						variant="filled"
						color="secondary"
						InputProps={{ className: classes.searchFieldInput }}
						sx={{ input: { color: '#000' } }}
						className={classes.searchField}
						onChange={(event) => handleSearch(event)}
					/>
					<TableContainer sx={{ height: 'calc(100vh - 350px)' }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell style={{ backgroundColor: '#D9D9D9' }}><Typography>Enhed</Typography></TableCell>
									<TableCell style={{ backgroundColor: '#D9D9D9' }} align="right"><AddIcon fontSize="large" onClick={() => handleAddDevice()} /></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
									? devicesFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: devicesFiltered
								).map((device) => (
									<TableRow key={device.uuid} style={{ cursor: 'pointer' }}>
										<TableCell component="th" scope="row" onClick={(event) => handleRowClick(event, device.uuid)}>
											<Typography>{device.name}</Typography>
											<Typography variant="body2">{device.address?.length && device.zip?.length && device.city?.length ? device.address + ', ' + device.zip + ' ' + device.city : ''}</Typography>
										</TableCell>
										<TableCell align='right'>
											<IconButton onClick={() => onboardDevice(device)}><QrCodeScannerIcon /></IconButton>
										</TableCell>
									</TableRow>
								))}

								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						style={{ backgroundColor: '#D9D9D9' }}
						rowsPerPageOptions={[5, 10, 25, { label: 'Alle', value: -1 }]}
						component="div"
						count={devicesFiltered.length}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage=""
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			) : <CircularLoader />}
		</OnboardContainer>

	)
}

export default DevicesList;