import React, { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, InputLabel, Typography } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';

import useGlobalState from '../hooks/useGlobalState';
import OnboardContainer from 'components/OnboardContainer';
import CircularLoader from 'components/ui/CircularLoader';
import { getPackages, getPackage, getDeviceType } from 'data/api';

const OnboardChoosePackage = () => {
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [templates, setTemplates] = useState([]);
	const [template, setTemplate] = useState('');
	const [fields, setFields] = useState([]);
	const [datafields, setDatafields] = useState({});
	const [dataTypeFields, setDataTypeFields] = useState([]);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: 'Vælg pakke', showMobileStepper: true, showBottomNavigation: false, activeStep: 7 });
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			const templateData = await getPackages();

			if (templateData?.status === 'ok') {
				setTemplates(templateData.result);

				const deviceTypeData = await getDeviceType(state.selectedDeviceType);

				if (deviceTypeData) {
					setDataTypeFields(deviceTypeData.outbound);
				}

				if (state.selectedPackage) {
					setTemplate(state.selectedPackage);
					handleTemplateChange(state.selectedPackage);
				}

				if (state.selectedDatafields) {
					setDatafields(state.selectedDatafields);
				}
			}

			setLoading(false);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleTemplateChange = async (value) => {
		setTemplate(value);

		const packageData = await getPackage(value);

		if (packageData?.status === 'ok') {
			let newFields = [];
			packageData.result.widgets.forEach(widget => {
				if (widget.fields) {
					widget.fields.forEach(field => {
						newFields.push(field);
					});
				}
			});
			setFields(newFields);
		}

		dispatch({ selectedPackage: value });
	}

	const handleFieldChange = (value, uuid) => {
		let newDataFields = { ...datafields };

		newDataFields[uuid] = value;

		setDatafields(newDataFields);

		dispatch({ selectedDatafields: newDataFields });
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			{!loading ? (
				<>
					<FormControl fullWidth style={{ marginTop: 20 }}>
						<InputLabel id="select-tool-label">Vælg pakke</InputLabel>

						<Select
							labelId="select-template-label"
							id="select-template"
							value={template}
							label="Vælg pakke"
							onChange={(event) => handleTemplateChange(event.target.value)}
						>
							{templates.map(d => {
								return <MenuItem key={d.uuid} value={d.uuid}>{d.name}</MenuItem>;

							})}
						</Select>
					</FormControl>

					{fields.length ?
						<>
							<Typography variant='h5' align='center' style={{ marginTop: 30 }}>Vælg målepunkter</Typography>
							{fields.map(field => {
								return (
									<FormControl fullWidth style={{ marginTop: 20 }} key={field.uuid}>
										<InputLabel id={'select-field-label-' + field.uuid}>{field.legend}</InputLabel>

										<Select
											labelId={'select-field-label-' + field.uuid}
											id={'select-field-' + field.uuid}
											value={datafields[field.uuid] !== undefined ? datafields[field.uuid] : ''}
											label={field.legend}
											onChange={(event) => handleFieldChange(event.target.value, field.uuid)}
										>
											{dataTypeFields.map(d => {
												return <MenuItem key={d.key} value={d.key}>{d.key}</MenuItem>;

											})}
										</Select>
									</FormControl>
								)
							})}
						</>
						: <></>
					}
				</>
			) : <CircularLoader />}
		</OnboardContainer>
	)
}

export default OnboardChoosePackage;