import React, { useState } from 'react';
import { Typography, Container, Paper, TextField, Grid, Button, Divider, Link } from '@mui/material';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import cookie from 'react-cookies';

import loginStyles from 'styles/loginStyles';
import { setToken, loginUser, getUser } from 'data/api';
import useEventListener from 'hooks/useEventListener';
import CircularLoader from 'components/ui/CircularLoader';
import useGlobalState from 'hooks/useGlobalState';

const Login = () => {
	const classes = loginStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const [, dispatch] = useGlobalState();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loggingIn, setLoggingIn] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');

	const handleLogin = async () => {
		setLoggingIn(true);

		setErrorMsg('');

		await loginUser(username, password).then(async rs => {
			if (rs) {
				if (rs.error) {
					let msg = '';
					switch (rs.error) {
						case 'login.accountDeleted':
							msg = 'Kontoen er slettet.';
							break;
						case 'login.accountNotActive':
							msg = 'Kontoen er ikke aktiveret.';
							break;
						case 'login.accountDeactivated':
							msg = 'Kontoen er deaktiveret.';
							break;
						case 'login.wrongPassword':
							msg = 'Der er indtastet en forkert adgangskode.';
							break;
						case 'login.accountNotFound':
							msg = 'Konto eller adgangskode er forkert.';
							break;
						case 'login.missingOrganisation':
							msg = 'Der er ikke indtastet en organisation.';
							break;
						case 'login.missingUsername':
							msg = 'Der er ikke indtastet et brugernavn.';
							break;
						case 'login.missingPassword':
							msg = 'Der er ikke indtastet en adgangskode.';
							break;
						default:
						case 'login.unknownError':
							msg = 'Der opstod en uventet fejl, prøv venligst igen.';
							break;
					}

					setErrorMsg(msg);
					setLoggingIn(false);
				} else {
					cookie.save('SESSION', rs, { path: '/', expires: moment().add('30', 'days').toDate() });

					if (setToken()) {
						const userData = await getUser();
	
						if (userData) {
							dispatch({ user: userData });
						}

						navigate(location.state?.from?.pathname || '/', { replace: true });
					}
				}
			} else {
				setErrorMsg('Kunne ikke logge ind.');

				setLoggingIn(false);
			}
		});
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleLogin();
		}
	};

	useEventListener('keypress', handleKeyPress);

	return (
		<Container component="main" maxWidth="sm">
			<div className={classes.logoWrapper}>
				<img src='/logo_pacco.svg' alt="Senti" className={classes.logoImage} />
			</div>

			<Paper elevation={3} className={classes.loginPaper}>
				<Typography className={classes.loginHeader}>Pacco Device Configuration</Typography>

				{errorMsg ? <Typography variant="body1" color="error" style={{ marginTop: 20 }}>{errorMsg}</Typography> : ""}

				{!loggingIn ?
					<>
						<Grid container>
							<Grid item xs={12}>
								<TextField
									autoFocus
									id="username"
									label="Brugernavn"
									variant="outlined"
									className={classes.textField}
									onChange={(event) => setUsername(event.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="password"
									label="Adgangskode"
									type="password"
									variant="outlined"
									className={classes.textField}
									onChange={(event) => setPassword(event.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button onClick={handleLogin} variant="contained" className={classes.loginButton}>Log ind</Button>
							</Grid>
						</Grid>
					</>
					: <CircularLoader />}

				<Divider />

				<div className={classes.linksWrapper}>
					<Grid container>
						<Grid item xs={12}>
							<Link href="/password/reset/da" color="inherit" className={classes.forgotPasswordLink}>Glemt adgangskode?</Link>
						</Grid>
						<Grid item xs={12}>
							Brug for en konto? • <Link href="/password/reset/da" color="inherit" className={classes.createAccountLink}>Opret en konto</Link>
						</Grid>
					</Grid>
				</div>
			</Paper>

			<div className={classes.bottomWrapper}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Link href="/" color="#fff" variant="body2">Cookie politik</Link> • <Link href="/" color="#fff" variant="body2">Persondatapolitik</Link>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='body2' className={classes.copyrightText}>©2016-{moment().year()} Alle rettigheder forbeholdt. Pacco Device Configuration is powered by <b>Senti</b></Typography>
					</Grid>
				</Grid>

				<Typography variant='body2' className={classes.bottomText2}>Senti enables you to <b>Sense, Think and Act</b> on data from globally dispersed IoT devices</Typography>
			</div>
		</Container>
	)
}

export default Login;