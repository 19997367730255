import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';
import { defaultGlobalState } from 'providers/GlobalStateProvider';

const DeviceAdd = () => {
	const classes = mainStyles();
	const [, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [uuname, setUuname] = useState('');
	const [uunameError, setUunameError] = useState('');

	useEffect(() => {
		dispatch({ headerTitle: 'Opret ny enhed', showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch]);

	const handleOnboard = async () => {
		setUunameError('');

		if (!uuname.length) {
			setUunameError('Du skal indtaste et enheds ID');
		} else {
			delete defaultGlobalState.user;
			dispatch(defaultGlobalState);
			localStorage.removeItem('globalState');

			dispatch({ uuname: uuname });

			navigate('/onboard/customer');
		}
	}

	return (
		<OnboardContainer hasPadding={true} title="" subtitle="" icon={null} cancelRoute="/devices" noAvatar={true}>
			<Typography style={{ marginTop: 20 }}>Indtast enheds ID for at onboarde</Typography>

			<TextField
				id="uuname"
				label="Enhed ID"
				value={uuname}
				variant="outlined"
				color="secondary"
				className={classes.textField}
				onChange={(event) => setUuname(event.target.value)}
				error={uunameError.length ? true : false}
				helperText={uunameError}
			/>

			<Box className={classes.buttonPanel} justifyContent="center">
				<Button className={classes.greenButton} onClick={() => handleOnboard()}>Onboard</Button>
			</Box>
		</OnboardContainer>
	)
}

export default DeviceAdd;