import React, { useState, useEffect } from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useNavigate } from 'react-router-dom';

import OnboardContainer from 'components/OnboardContainer';
import useGlobalState from '../hooks/useGlobalState';
import CircularLoader from 'components/ui/CircularLoader';
import { getSettings, getTemplates, getTools } from 'data/api';

const OnboardChooseInstallation = () => {
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [tools, setTools] = useState([]);
	const [tool, setTool] = useState('');
	const [template, setTemplate] = useState('');
	const [templates, setTemplates] = useState([]);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: 'Vælg anlæg', showMobileStepper: true, showBottomNavigation: false, activeStep: 4 });
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			const settings = await getSettings();

			const owner = settings.find(d => d.key === 'microbizzowner');

			if (owner !== undefined && state.selectedCustomer) {
				dispatch({ owner: owner.value });

				const toolsData = await getTools(owner.value, state.selectedCustomer);

				if (toolsData?.status === 'ok') {
					setTools(toolsData.result);

					if (state.selectedTool) {
						setTool(state.selectedTool);
					}
				}

				const templatesData = await getTemplates(owner.value);

				if (templatesData?.status === 'ok') {
					setTemplates(templatesData.result.filter(d => d.active === 1));

					if (state.selectedTemplate) {
						setTemplate(state.selectedTemplate);
					}
				}
			}

			setLoading(false);
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleToolChange = (value) => {
		setTool(value);

		dispatch({ selectedTool: value });
	}

	const handleTemplateChange = (value) => {
		setTemplate(value);

		dispatch({ selectedTemplate: value });
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={state.uuname} icon={<TimelineIcon />} cancelRoute="/device">
			{!loading ? (
				<>
					{tools?.length && templates?.length ? (
						<>
							<Typography variant="h3" align="center">Vælg anlæg</Typography>

							<FormControl fullWidth style={{ marginTop: 20 }}>
								<InputLabel id="select-tool-label">Anlæg</InputLabel>
								<Select
									labelId="select-tool-label"
									id="select-tool"
									value={tool}
									label="Anlæg"
									onChange={(event) => handleToolChange(event.target.value)}
								>
									{tools.map(d => {
										return <MenuItem key={d.toolId} value={d.toolId}>{d.name}</MenuItem>;

									})}
								</Select>
							</FormControl>

							<FormControl fullWidth style={{ marginTop: 20 }}>
								<InputLabel id="select-template-label">Skabelon</InputLabel>
								<Select
									labelId="select-template-label"
									id="select-template"
									value={template}
									label="Skabelon"
									onChange={(event) => handleTemplateChange(event.target.value)}
								>
									{templates.map(d => {
										return <MenuItem key={d.templateId} value={d.templateId}>{d.name}</MenuItem>;

									})}
								</Select>
							</FormControl>
						</>) : (
						<Typography>Der findes ingen anlæg/skabeloner på valgte kunde</Typography>
					)}
				</>
			) : (<CircularLoader />)}
		</OnboardContainer>
	)
}

export default OnboardChooseInstallation;