import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Box, Button } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useParams, useNavigate } from 'react-router-dom';

import mainStyles from 'styles/mainStyles';
import useGlobalState from 'hooks/useGlobalState';
import { getDeviceByUuname } from 'data/api';
import OnboardContainer from 'components/OnboardContainer';
import CircularLoader from 'components/ui/CircularLoader';
import { defaultGlobalState } from 'providers/GlobalStateProvider';

const Onboard = () => {
	const classes = mainStyles();
	const params = useParams();
	const navigate = useNavigate();
	const [state, dispatch] = useGlobalState();

	const [loading, setLoading] = useState(true);
	const [device, setDevice] = useState(null);

	useEffect(() => {
		dispatch({ headerTitle: 'Enhedskonfiguration', activeStep: 0, showMobileStepper: true, showBottomNavigation: false });
	}, [dispatch]);

	useEffect(() => {
		console.log('useEffect');

		async function fetchData() {
			const deviceData = await getDeviceByUuname(params.device);

			if (deviceData) {
				if (deviceData.status === 403 || deviceData.status === 404) {
					//not provisioned
				} else if (deviceData.status === 200) {
					setDevice(deviceData);

					dispatch({ device: deviceData });
				}
			}

			setLoading(false);
		}

		fetchData();
	}, [params.device, dispatch]);

	const handleConnect = () => {
		if (params.device !== state.uuname) {
			delete defaultGlobalState.user;
			dispatch(defaultGlobalState);
			localStorage.removeItem('globalState');
		}

		dispatch({ uuname: params.device, deviceType: params.deviceType });

		navigate('/onboard/customer');
	}

	const handleCancel = () => {
		navigate('/scan');
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={params.device} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			{!loading ?
				<>
					<List style={{ padding: 0 }}>
						<ListItem style={{ padding: 0 }}>
							<ListItemText primary="Enhed ID" secondary={params.device} />
						</ListItem>
						<ListItem style={{ padding: 0 }}>
							<ListItemText primary="Enhedstype" secondary={params.deviceType ? params.deviceType : '-'} />
						</ListItem>
						<ListItem style={{ padding: 0 }}>
							<ListItemText primary="Enhed status" secondary={device === null ? 'Not provisioned' : 'Provisioned'} />
						</ListItem>
					</List>

					<Typography style={{ marginTop: 10 }}>For at forbinde denne enhed til Senti tryk på FORBIND eller ANNULLER for at afbryde enhedskonfigurationen.</Typography>

					<Box className={classes.buttonPanel}>
						<Button className={classes.greenButton} onClick={() => handleConnect()}>Forbind</Button>
						<Button className={classes.redButton} onClick={() => handleCancel()}>Annuller</Button>
					</Box>
				</>
				: <CircularLoader />}
		</OnboardContainer>
	)
}

export default Onboard;