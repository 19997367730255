import React, { useEffect, useState } from 'react';
import { Typography, TextField } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import OnboardContainer from 'components/OnboardContainer';

const OnboardChooseName = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: 'Navngiv enhed', showMobileStepper: true, showBottomNavigation: false, activeStep: 3 });
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			if (state.device) {
				if (state.selectedName) {
					setName(state.selectedName);
				} else {
					setName(state.device.data.name);
					dispatch({ selectedName: state.device.data.name })
				}

				if (state.selectedDescription) {
					setDesc(state.selectedDescription);
				} else {
					setDesc(state.device.data.description);
					dispatch({ selectedDescription: state.device.data.description })
				}
			} else {
				setName(state.uuname);
				dispatch({ selectedName: state.uuname })
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveName = (data) => {
		setName(data);

		dispatch({ selectedName: data });
	}

	const saveDesc = (data) => {
		setDesc(data);

		dispatch({ selectedDescription: data });
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			<Typography variant="h3" align="center">Enhed og beskrivelse</Typography>

			<Typography style={{ marginTop: 20 }}>Indtast et enhedsnavn og informativ beskrivelse</Typography>

			<TextField
				id="name"
				label="Navn"
				value={name}
				variant="outlined"
				color="secondary"
				className={classes.textField}
				onChange={(event) => saveName(event.target.value)}
			/>

			<TextField
				multiline
				id="desc"
				label="Beskrivelse"
				value={desc}
				variant="outlined"
				color="secondary"
				className={classes.textField}
				onChange={(event) => saveDesc(event.target.value)}
				inputProps={{
					style: {
						height: "200px",
					},
				}}
			/>
		</OnboardContainer>
	)
}

export default OnboardChooseName;