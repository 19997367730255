import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useNavigate } from 'react-router-dom';

import useGlobalState from '../hooks/useGlobalState';
import mainStyles from 'styles/mainStyles';
import { getAllCollectionsFromServer } from 'data/api';
import CircularLoader from 'components/ui/CircularLoader';
import OnboardContainer from 'components/OnboardContainer';

const OnboardChooseBuilding = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [collections, setCollections] = useState([]);
	const [collectionsFiltered, setCollectionsFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [selectedUuid, setSelectedUuid] = useState(null);

	useEffect(() => {
		if (!state.uuname) {
			navigate('/');
		}
	}, [state, navigate]);

	useEffect(() => {
		dispatch({ headerTitle: 'Vælg lokation', showMobileStepper: true, showBottomNavigation: false, activeStep: 5 });
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {
			const collData = await getAllCollectionsFromServer();

			if (collData) {
				let newCollections = [];
				collData.forEach(c => {
					if (c.parent === null) {
						newCollections.push(c);
					}
				});

				setCollections(newCollections);
				setCollectionsFiltered(newCollections);

				if (state.selectedBuilding) {
					setSelectedUuid(state.selectedBuilding);
				}
			}

			setLoading(false);
		}

		fetchData();
	}, [state.selectedBuilding]);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - collectionsFiltered.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event) => {
		const rpp = parseInt(event.target.value, 10);
		setRowsPerPage(rpp);

		if (selectedUuid && event.target.value !== -1) {
			const index = collections.findIndex(o => o.uuid === selectedUuid);

			setPage(Math.floor(index / rpp));
		} else {
			setPage(0);
		}
	}

	const handleRowClick = (event, uuid) => {
		setSelectedUuid(uuid);

		dispatch({ selectedBuilding: uuid });
	}

	const handleSearch = (event) => {
		setPage(0);

		let newDeviceTypes = collections.filter(c =>
			c.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
			c.address.toLowerCase().includes(event.target.value.toLowerCase())
		);

		setCollectionsFiltered(newDeviceTypes);
	}

	const handleAddCollection = () => {
		navigate('/onboard/buildingadd');
	}

	return (
		<OnboardContainer hasPadding={true} title="ENHED ID:" subtitle={state.uuname} icon={<QrCodeScannerIcon />} cancelRoute="/scan">
			{!loading ? (
				<>
					<TextField
						id="table-search"
						label="Søg på lokation eller adresse"
						type="search"
						variant="filled"
						color="secondary"
						InputProps={{ className: classes.searchFieldInput }}
						sx={{ input: { color: '#000' } }}
						className={classes.searchField}
						onChange={(event) => handleSearch(event)}
					/>
					<TableContainer sx={{ height: 'calc(100vh - 380px)' }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell style={{ backgroundColor: '#D9D9D9' }}><Typography>Lokation</Typography></TableCell>
									<TableCell style={{ backgroundColor: '#D9D9D9' }} align="right"><AddIcon fontSize="large" onClick={() => handleAddCollection()} /></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
									? collectionsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: collectionsFiltered
								).map((c) => (
									<TableRow key={c.uuid} onClick={(event) => handleRowClick(event, c.uuid)} style={{ cursor: 'pointer' }}>
										<TableCell component="th" scope="row" colSpan={2} style={{ backgroundColor: (selectedUuid === c.uuid ? '#DDE4ED' : '#F6F6F6') }}>
											<Typography>{c.name}</Typography>
										</TableCell>
									</TableRow>
								))}

								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						style={{ backgroundColor: '#D9D9D9' }}
						rowsPerPageOptions={[5, 10, 25, { label: 'Alle', value: -1 }]}
						component="div"
						count={collectionsFiltered.length}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage=""
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			) : <CircularLoader />}
		</OnboardContainer>
	)
}

export default OnboardChooseBuilding;