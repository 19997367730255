import React, { useEffect } from 'react';
import { Container, Paper, Card, CardHeader, Avatar, CardContent } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';

import mainStyles from 'styles/mainStyles';
import useGlobalState from '../hooks/useGlobalState';

const DeviceData = () => {
	const classes = mainStyles();
	const [state, dispatch] = useGlobalState();

	useEffect(() => {
		dispatch({ headerTitle: 'Enhed data', showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch]);

	return (
		<Container component="main" maxWidth="lg" className={classes.container}>
			<>
				<Card elevation={0}>
					<CardHeader
						avatar={
							<Avatar>
								<TimelineIcon htmlColor="#ED1C24" />
							</Avatar>
						}
						title={state.onboardedDevice.name}
						subheader={state.onboardedDevice.uuname}
					/>
					<CardContent>
						<Paper className={classes.paperLessPadding}>

						</Paper>
					</CardContent>
				</Card>
			</>
		</Container>
	)
}

export default DeviceData;