import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Container, Box, Button } from '@mui/material';
import Webcam from "react-webcam";
import QrScanner from 'qr-scanner';
import { useNavigate } from 'react-router-dom';

import mainStyles from 'styles/mainStyles';
import useGlobalState from '../hooks/useGlobalState';
import Alert from 'components/ui/Alert';

const ScanDevice = () => {
	const classes = mainStyles();
	const [, dispatch] = useGlobalState();
	const navigate = useNavigate();

	const webcamRef = useRef(null);

	const [alertWrongOpen, setAlertWrongOpen] = useState(false);
	const [alertErrorOpen, setAlertErrorOpen] = useState(false);

	useEffect(() => {
		dispatch({ headerTitle: 'Scan enhed', device: null, deviceType: null, showMobileStepper: false, showBottomNavigation: true });
	}, [dispatch]);

	const scan = useCallback(
		() => {
			const imageData = webcamRef.current.getScreenshot();

			QrScanner.scanImage(imageData)
    		.then(result => {
					const resultParts = result.split('/');
					if (resultParts[3] !== 'onboard') {
						setAlertWrongOpen(true);
					} else {
						navigate('/' + resultParts[4] + '/' + resultParts[5]);
					}
				})
    		.catch(error => {
					setAlertErrorOpen(true);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// const cancel = () => {

	// }

	const closeAlert = () => {
		setAlertErrorOpen(false);
		setAlertWrongOpen(false);
	}

	return (
		<Container component="main" maxWidth="lg" className={classes.containerWithPadding}>
			<Webcam
				style={{ width: '100%', maxHeight: 500, marginTop: 30 }}
				ref={webcamRef}
				screenshotFormat="image/jpg"
				screenshotQuality={0.8}
				videoConstraints={{ facingMode: "environment" }}
			/>

			<Box className={classes.buttonPanel} justifyContent="center">
				<Button className={classes.greenButton} onClick={() => scan()}>Scan</Button>
				{/* <Button className={classes.redButton} onClick={() => cancel()}>Annuller</Button> */}
			</Box>

			<Alert text="Kunne ikke genkende QR koden, prøv venligst igen!" visible={alertWrongOpen} handleClose={closeAlert} />
			<Alert text="Der blev ikke fundet en QR kode på billedet, prøv venligst igen!" visible={alertErrorOpen} handleClose={closeAlert} />
		</Container>
	)
}

export default ScanDevice;